import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Counter} from '~library/atoms/Counter';
import {Divider} from '~library/atoms/Divider';
import {Typography} from '~library/atoms/Typography';
import {AlertLineIcon} from '~library/svg/AlertLineIcon';
import {CloseLineIcon} from '~library/svg/CloseLineIcon';
import {useMatchQuery} from '~library/hooks/useMatchQuery';
import {Input} from '~library/atoms/Input';
import {Checkbox} from '~library/atoms/Checkbox';
import {Button} from '~library/atoms/Button';
import {toastAlert} from '~library/atoms/Toast';
import {isSaveTheDateByUsedAs} from '~/common/utils/event_utils';
import {validateEmail} from '~/ViewInvitation/utils/validateEmail';
import {largishAndUp, mediumAndUp, charcoal} from '~libSass/base/_exports.sass';
import {ResponsiveModal} from '~library/organisms/ResponsiveModal';
import {ResponsivePanel} from '~library/organisms/ResponsivePanel';
import {ArrowReinventionIcon} from '~library/svg/ArrowReinventionIcon';

export const RSVPModal = ({
  status = 'No',
  onClose,
  onSubmitRSVP,
  open,
  overlayCloseBtnDataQAId,
  overlayDataImpressionTracking,
}) => {
  const history = useHistory();
  const location = useLocation();

  const {matches: largeScreens} = useMatchQuery(`(min-width: ${largishAndUp})`);
  const {matches: mediumScreens} = useMatchQuery(`(min-width: ${mediumAndUp})`);
  const {
    notifications: notificationOptions,
    isSMSInvite,
    guestEmail,
    showNotifyMarketing,
    name,
    rsvpdAdults,
    rsvpdKids,
    rsvpdOptin,
    rsvpdOutout,
    hasRsvpd,
  } = useSelector((state) => ({
    ...state.rsvp,
    isSMSInvite: state.rsvp?.inviteMethod === 'sms',
    guestEmail: state.rsvp?.guestEmail ?? '',
    showNotifyMarketing: state.rsvp?.showNotifyMarketing ?? true,
    name: state.rsvp?.name ?? state.user?.name ?? '',
    rsvpdAdults: state.rsvp?.totalAdults ?? 0,
    rsvpdKids: state.rsvp?.totalKids ?? 0,
    rsvpdOptin: state.rsvp?.optin ?? false,
    rsvpdOutout: state.rsvp?.optout ?? false,
    hasRsvpd: state.rsvp?.hasRsvpd ?? false,
  }));

  const guest_id = useSelector((state) => state.user?.guestId ?? '');
  const {allowKids, maxAdditionalGuests, allowGuestsToBringMoreGuests, allowGuestsToInviteMore} =
    useSelector((state) => ({
      allowKids: !!state.eventDetails.allowKids,
      maxAdditionalGuests: state.eventDetails.maxGuests,
      allowGuestsToBringMoreGuests: state.eventDetails?.allowGuests ?? false,
      allowGuestsToInviteMore: state.eventDetails?.allowInviteMore ?? false,
    }));
  const allowGuestsToInvite = useSelector(
    (state) => !!allowGuestsToInviteMore && !!maxAdditionalGuests && !state.rsvp.guestOf
  );

  const isPrivateGuestlist = useSelector(
    (state) => state?.whoIsComing?.isPrivateGuestlist ?? false
  );
  const usedAs = useSelector((state) => state.eventDetails?.usedAs);
  const isSaveTheDate = isSaveTheDateByUsedAs(usedAs);

  const notificationsSubscribed =
    !!notificationOptions.push_new_comments ||
    !!notificationOptions.push_new_rsvp ||
    !!notificationOptions.email_new_comments ||
    !!notificationOptions.notify_only_my_activity;

  const [replyAsName, setReplyAsName] = useState('');
  const [replyAsEmail, setReplyAsEmail] = useState('');
  const [rsvpComment, setRsvpComment] = useState('');
  const [eventNotifications, setEventNotifications] = useState(notificationsSubscribed);
  const [marketingNotifications, setMarketingNotifications] = useState(true);
  const [notifyGuestRSVPs, setNotifyGuestRSVPs] = useState(notificationOptions.email_new_rsvp);
  const [editOn, setEditOn] = useState(false);
  const [notYou, setNotYou] = useState(false);
  const [forceRSVPForm, setForceRSVPForm] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  const [numAdults, setNumAdults] = useState(1);
  const [numKids, setNumKids] = useState(0);

  const [rsvpDone, setRsvpDone] = useState(false);

  useEffect(() => {
    if (rsvpDone) {
      history.replace(`/frp/rsvp?gid=${window.guest_id}`, location.state);
    }
  }, [rsvpDone]);

  useEffect(() => {
    const subscribed =
      !!notificationOptions.push_new_comments ||
      !!notificationOptions.push_new_rsvp ||
      !!notificationOptions.email_new_comments ||
      !!notificationOptions.notify_only_my_activity;

    setReplyAsName(name);
    setReplyAsEmail(guestEmail);
    if (!guestEmail || !name) setForceRSVPForm(true);

    setNumAdults(rsvpdAdults);
    setNumKids(rsvpdKids);
    setEventNotifications(!hasRsvpd ? true : subscribed);
    setMarketingNotifications(rsvpdOptin || !rsvpdOutout);
    setNotifyGuestRSVPs(notificationOptions.email_new_rsvp);
  }, [name, guestEmail, rsvpdAdults, rsvpdKids, rsvpdOptin, rsvpdOutout, notificationOptions]);

  const onNotYouPress = () => {
    setReplyAsName('');
    setReplyAsEmail('');
    setNotYou(true);
  };

  const onChangeReplyAsCancel = () => {
    setEditOn(false);
    setNotYou(false);
    setReplyAsName(name);
    setReplyAsEmail(guestEmail);
  };

  const onEditPress = () => {
    setEditOn(true);
  };

  const onSubmit = (e) =>
    new Promise((resolve, reject) => {
      e.preventDefault();
      e.stopPropagation();
      let email = replyAsEmail?.trim() || guestEmail;
      if (!status) {
        // Somehow the modal has been opened without an RSVP status; do not submit RSVP
        toastAlert('No RSVP status chosen', {
          type: 'error',
          icon: <AlertLineIcon size={0.75} />,
        });
        reject(new Error('No RSVP status chosen'));
        return;
      }
      if (notYou || forceRSVPForm) {
        const res = validateEmail(replyAsEmail);
        if (!res.valid) {
          toastAlert(res.message, {
            type: 'error',
            icon: <AlertLineIcon size={0.75} />,
          });
          reject(res.message);
          return;
        }
        email = res.value;
      }
      let payload = {
        name: replyAsName.trim(),
        email,
        response: status.toLowerCase(),
        adults_in_party: numAdults,
        kids_in_party: numKids,
        comments: rsvpComment,
        optin: marketingNotifications,
      };
      // Update notification
      if (eventNotifications !== notificationsSubscribed) {
        payload = {
          ...payload,
          notification_options: {
            email_new_comments: eventNotifications,
            email_new_rsvp: !!notifyGuestRSVPs,
            push_new_comments: eventNotifications,
            push_new_rsvp: !hasRsvpd
              ? eventNotifications
              : eventNotifications && notificationOptions.push_new_rsvp,
            notify_only_my_activity: eventNotifications,
            notify_only_my_activity_push: eventNotifications,
            notify_game: {},
          },
        };
      } else {
        payload = {
          ...payload,
          notification_options: {
            email_new_comments: !!notificationOptions.email_new_comments,
            email_new_rsvp: !!notifyGuestRSVPs,
            push_new_comments: !!notificationOptions.push_new_comments,
            push_new_rsvp: !!notificationOptions.push_new_rsvp,
            notify_only_my_activity: !!notificationOptions.notify_only_my_activity,
            notify_only_my_activity_push: !!notificationOptions.notify_only_my_activity_push,
            notify_game: {},
          },
        };
      }
      if (guest_id && (!replyAsEmail || guestEmail === replyAsEmail?.trim() || isSMSInvite)) {
        payload = {...payload, guest_id};
      }
      onSubmitRSVP(guest_id, payload)
        .then(() => {
          setEditOn(false);
          setNotYou(false);
          setRsvpDone(true);
          resolve();
        })
        .catch(reject);
    });

  const body = (
    <form onSubmit={onSubmit}>
      {mediumScreens ? (
        <div className="rsvp-modal-variant__large-title">
          <Typography variant="header2">You are RSVPing</Typography>{' '}
          {status !== 'No' ? (
            <Typography className="rsvp-modal-variant__yes-title" variant="header2">
              Yes
            </Typography>
          ) : (
            <Typography variant="header2">No</Typography>
          )}
        </div>
      ) : (
        <>
          <div className="rsvp-modal-variant__large-title-mobile-container">
            <div className="rsvp-modal-variant__large-title-mobile">
              <Typography variant={largeScreens ? 'header2' : 'header3'}>
                You are RSVPing
              </Typography>{' '}
              {status !== 'No' ? (
                <Typography
                  className="rsvp-modal-variant__yes-title"
                  variant={largeScreens ? 'header2' : 'header3'}
                >
                  Yes
                </Typography>
              ) : (
                <Typography variant={largeScreens ? 'header2' : 'header3'}>No</Typography>
              )}
            </div>
            <Button
              data-qa-id={overlayCloseBtnDataQAId}
              data-impression-tracking={overlayDataImpressionTracking ? 'true' : 'false'}
              variant="transparent"
              className="rsvp-modal-close-button-mobile"
              onClick={onClose}
            >
              <CloseLineIcon ratio={0.75} />
            </Button>
          </div>
          <Divider marginTop="1.5rem" marginBottom="1.5rem" />
        </>
      )}
      <div className="rsvp-modal-variant__body">
        <div
          className={`rsvp-modal__reply-as-wrapper ${
            editOn || notYou || forceRSVPForm ? 'edit-on' : ''
          }`}
        >
          {editOn || notYou || forceRSVPForm ? (
            <>
              <Input
                data-qa-id="replyas-name"
                value={replyAsName}
                label="Your name"
                onChange={(e) => setReplyAsName(e.target.value)}
                placeholder="Enter your name"
              />
              {(notYou || forceRSVPForm) && (
                <Input
                  data-qa-id="replyas-email"
                  value={replyAsEmail}
                  label="Your email"
                  type="email"
                  autoComplete="email"
                  onChange={(e) => setReplyAsEmail(e.target.value)}
                  placeholder="Enter your email"
                />
              )}
            </>
          ) : (
            <p className={`rsvp-modal__reply-as-text ${largeScreens ? 'large-screens' : ''}`}>
              Replying as:{' '}
              <span className="rsvp-modal__reply-as-name" data-qa-id="replyas-saved-name">
                {replyAsName}
              </span>
            </p>
          )}
          {!forceRSVPForm && (
            <div className="rsvp-modal__reply-as-ctas">
              {editOn || notYou ? (
                <button
                  type="button"
                  className="unstyled-button link small"
                  data-qa-id="cancel-edit-replyas"
                  onClick={onChangeReplyAsCancel}
                >
                  Cancel
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="unstyled-button link small"
                    data-qa-id="rsvp-edit-replyas"
                    onClick={onEditPress}
                  >
                    Edit
                  </button>
                  {allowGuestsToInvite && (
                    <>
                      <span>|</span>
                      <button
                        type="button"
                        className="unstyled-button link small"
                        data-qa-id="rsvp-not-you"
                        onClick={onNotYouPress}
                      >
                        Not you?
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <div className="rsvp-modal-variant__rsvp-details-wrapper">
          {status !== 'No' && allowGuestsToBringMoreGuests ? (
            <>
              <div className="rsvp-modal-variant__rsvp-counters">
                <Counter
                  count={numAdults}
                  title={allowKids ? 'Total Adults' : 'Total Guests'}
                  className="rsvp-modal-counter"
                  onPlus={() => setNumAdults(numAdults + 1)}
                  onMinus={() => {
                    if (numAdults > 0) setNumAdults(numAdults - 1);
                  }}
                  data-qa-id="adults-count"
                  inputCallback={(val) => setNumAdults(val)}
                />
                {allowKids && (
                  <Counter
                    title="Total Kids"
                    count={numKids}
                    className="rsvp-modal-counter"
                    onPlus={() => setNumKids(numKids + 1)}
                    onMinus={() => {
                      if (numKids > 0) setNumKids(numKids - 1);
                    }}
                    data-qa-id="kids-count"
                    inputCallback={(val) => setNumKids(val)}
                  />
                )}
              </div>
              <Divider />
            </>
          ) : (
            <Divider />
          )}
          <Button
            className="rsvp-modal__section-title"
            variant="transparent"
            data-qa-id="rsvp-modal-add-note-section"
            onClick={() => {
              if (activeSection === 'note') {
                setActiveSection('');
              } else {
                setActiveSection('note');
              }
            }}
          >
            <Typography variant="list2">Add a note to your RSVP</Typography>
            <ArrowReinventionIcon
              direction={activeSection !== 'note' ? 'up' : 'down'}
              ratio={0.75}
              color={charcoal}
            />
          </Button>
          {activeSection === 'note' && (
            <>
              {!isPrivateGuestlist && (
                <div className="rsvp-modal__note_section-content">
                  <Typography variant="list1" color="pebble">
                    This will be visible to all other guests
                  </Typography>
                </div>
              )}
              <Input
                data-qa-id="rsvp-comment"
                value={rsvpComment}
                onChange={(e) => setRsvpComment(e.target.value)}
                placeholder="Add a note"
              />
            </>
          )}
        </div>
        <Divider />
        <div className="rsvp-modal__notification-manager">
          <div
            onKeyDown={null}
            tabIndex={-1}
            role="button"
            className="rsvp-modal__section-title"
            data-qa-id="rsvp-modal-edit-notifications-section"
            onClick={() => {
              if (activeSection === 'notification') {
                setActiveSection('');
              } else {
                setActiveSection('notification');
              }
            }}
          >
            <Typography variant="list2">Edit notification settings</Typography>
            <ArrowReinventionIcon
              direction={activeSection !== 'notification' ? 'up' : 'down'}
              ratio={0.75}
              color={charcoal}
            />
          </div>
          {activeSection === 'notification' && (
            <div className="rsvp-modal__notification_section-label">
              <div className="rsvp-modal__notification_section-content">
                <Typography color="pebble" variant="paragraph3">
                  You will receive event reminders, cancellation notices and messages from the
                  host(s). Select additional notifications below:
                </Typography>
              </div>
              <Checkbox
                onChange={() => setEventNotifications(!eventNotifications)}
                data-qa-id="receive-event-notifications"
                checked={eventNotifications}
                label={<Typography className="paragraph3">All event activity</Typography>}
              />
              {showNotifyMarketing && (
                <Checkbox
                  onChange={() => setMarketingNotifications(!marketingNotifications)}
                  data-qa-id="receive-marketing-notifications"
                  checked={marketingNotifications}
                  label={
                    <Typography className="paragraph3">
                      Get America&apos;s trendiest parties delivered right to my inbox
                    </Typography>
                  }
                />
              )}
              {!isPrivateGuestlist && !isSaveTheDate && (
                <Checkbox
                  onChange={() => setNotifyGuestRSVPs(!notifyGuestRSVPs)}
                  data-qa-id="notify-guest-rsvps"
                  checked={notifyGuestRSVPs}
                  label={<Typography className="paragraph3">RSVPs from other guests</Typography>}
                />
              )}
            </div>
          )}
        </div>
        <Button
          data-qa-id="submit-rsvp"
          variant="primary"
          className="rsvp_modal_variant-button"
          size="large"
          type="submit"
          processingChildren="Submitting..."
          data-impression-tracking="true"
        >
          Submit RSVP
        </Button>
      </div>
    </form>
  );

  return mediumScreens ? (
    <ResponsiveModal
      isModal
      isOpen={open}
      onClose={onClose}
      showHeader={false}
      closeOnClickOutside={false}
    >
      {body}
    </ResponsiveModal>
  ) : (
    <ResponsivePanel
      isOpen={open}
      onClose={onClose}
      title="Guest of Honor"
      modalId="guest-of-honor"
      showHeader={false}
      closeOnClickOutside={false}
      showCover
    >
      {body}
    </ResponsivePanel>
  );
};

RSVPModal.propTypes = {
  status: PropTypes.oneOf(['Yes', 'Maybe', 'No']),
  onClose: PropTypes.func,
  onSubmitRSVP: PropTypes.func,
  open: PropTypes.bool,
};
